import React from "react"
import styled from "styled-components"
import DiffAnswer from "./diff-answer"

const PreviousAnswers = styled.div`
  margin: 50px auto;
  max-width: 500px;

  ${props => props.answers.length === 0 && "display: none;"}

  .prevContainer {
    border: 1px solid var(--borderPreviousAnswer);
    max-height: 400px;
    overflow: auto;
  }

  legend {
    font-size: 18px;
    margin: auto;
  }
  h4 {
    margin-bottom: 0;
  }
  a {
    color: #03a9f4;
    text-decoration: none;
    ${props => props.answers.length < 4 && "display: none;"}
  }
  .item {
    padding: 10px;
  }
  div>.item:nth-child(odd){
    background-color:  var(--bgOddPrevItem);
  }
`

export default (props) => (
  <PreviousAnswers answers={props.answers}>
    <legend>Previous Answers</legend>
    <div className="prevContainer">
      <span id="top"></span>
      {props.answers.map(function(d, idx){
        return (<div className="item" key={idx}>
          <DiffAnswer input={d.input} answer={d.answer} />
          <h4>{d.english}</h4>
          </div>
      )
      })}
      <a href="#top">▲ Back to Top ▲</a>
    </div>
  </PreviousAnswers>
)
