import React from "react"
import PropTypes from "prop-types"
import Fuse from "fuse.js"

import QuizMaster from "./quiz_master"
import Context from "../../components/quiz/context"
import PreviousAnswers from "../../components/japanese/sentence/previous-answers"

const Furigana = require("gem-furigana").Furigana
const names = require("../japanese/data/names.json")
const gender_opts = {
  "his/her": {"m": "his", "f": "her"},
  "him/her": {"m": "him", "f": "her"},
  "he/she": {"m": "he", "f": "she"},
}
const cities = require("../japanese/data/cities.json")

class SentenceTrainer extends React.Component  {
  constructor(props) {
    super(props);
    this.answers = []
    this.sentence = {};
    this.lastsentence = {};
    this.correct = 0;
    this.incorrect = 0;
    this.prevAnswers = []
    this.newSentence = this.newSentence.bind(this);
    this.checkAnswer = this.checkAnswer.bind(this);

    this.state = {
        loaded: false,
        status: "",
        wait: false,
        context: "",
        english: "",
        answer: "",
        userInput: "",
        correct: this.correct,
        incorrect: this.incorrect,
        prevAnswers: this.prevAnswers,
        placeHolder: "",
    };
  }

  newSentence(){
    let sentence = QuizMaster.generateItem(this.props.sentenceData, this.lastsentence)
    this.lastsentence = sentence;
    sentence = this.getNames(sentence);
    sentence = this.getCities(sentence);
    this.sentence = sentence;
    this.english = this.sentence.english;
    let context = this.sentence.context ? this.sentence.context : "";
    let placeHolder = this.sentence.parts.toString().match(/[\u30a0-\u30ff]/) ? "Press/Hold Shift⇧ to Type Katakana" : "";
    this.setState({ sentence: this.sentence, lastsentence: sentence, english: this.english, context: context, wait: false, status: "", placeHolder: placeHolder });

    this.getAnswers(this.prepareParts(this.sentence.parts));
  }

  getNames(sentence) {
    let new_sentence = JSON.parse(JSON.stringify(sentence));
    var index = sentence.parts.indexOf("#name");
    if (index !== -1) {
        var name = names[Math.floor(Math.random() * names.length)]
        new_sentence.parts[index] = name.jp;
        new_sentence.english = sentence.english.replace("#name", name.eng)
        var gender = name.gen;
        if (gender === "n") {gender = ["m", "f"][Math.floor(Math.random() * 2)]}
        new_sentence.english = new_sentence.english.replace("his/her", gender_opts["his/her"][gender])
        new_sentence.english = new_sentence.english.replace("him/her", gender_opts["him/her"][gender])
        new_sentence.english = new_sentence.english.replace("he/she", gender_opts["he/she"][gender])
    }
    return new_sentence;
  }

  getCities(sentence) {
    let new_sentence = JSON.parse(JSON.stringify(sentence));
    var index = sentence.parts.indexOf("#city");
    if (index !== -1) {
        var city = cities[Math.floor(Math.random() * cities.length)]
        new_sentence.parts[index] = city.jp;
        new_sentence.english = sentence.english.replace("#city", city.eng)
    }
    return new_sentence;
  }

  prepareParts(parts) {
    var self = this;
    var new_parts = []
    parts.forEach(function(part, i){
      if(typeof part === 'string') {
        if(part.includes("[")) {new_parts[i] = [part, new Furigana(part).Hiragana]}
        else {new_parts[i] = [part]}
      } else if (Array.isArray(part)) {
        new_parts[i] = self.prepareParts(part);
      }
    });
    var final_parts = new_parts.map(function(part) {
      if (Array.isArray(part)) {
        return [].concat(...part);
      }
      return part;
    });
    return final_parts;
  }

  getAnswers(arg) {
		//Takes Sentence Parts Array of Arrays as Input
    //Returns an Array of Sentence sting combinations
    var r = [], max = arg.length-1;
    function helper(arr, i) {
        for (var j=0, l=arg[i].length; j<l; j++) {
            var a = arr.slice(0); // clone arr
            a.push(arg[i][j])
            if (i === max) {
                r.push(a.join(""));
            } else {
                helper(a, i+1);
            }
        }
    }
    helper([], 0);
    this.answers = r;
  }

  closestAnswer(input, answers) {
    var options = {
      shouldSort: true,
      threshold: 1,
    };
    var cleanAnswers = answers.map(function(answer){
      return new Furigana(answer).Expression;
    })
    var fuse = new Fuse(cleanAnswers, options);
    var result = fuse.search(input);
    if (result.length === 0) {
      return answers[0]
    }
    return answers[result[0]];
  }

  checkAnswer(userAnswer) {
    var status = "";
    if (userAnswer.slice(-1) === "。") {userAnswer = userAnswer.slice(0, -1);}
    var bestAnswer = this.closestAnswer(userAnswer, this.answers);
    if(userAnswer === new Furigana(bestAnswer).Expression) {
      status = "correct";
      this.correct += 1;
    } else {
      status = "incorrect";
      this.incorrect += 1;
    }
    this.prevAnswers.unshift({input: userAnswer, answer:bestAnswer, english:this.english})
    if(this.prevAnswers.length > 100){this.prevAnswers.pop()}
    this.setState({ status: status, wait: true, correct: this.correct, incorrect: this.incorrect, userInput: userAnswer, answer: bestAnswer, prevAnswers:this.prevAnswers })
  }

  componentDidMount() {
    if(!this.state.loaded) {
      this.newSentence()
      this.setState({ loaded: true });
    }
  }

  render() {
    return (
      <QuizMaster
        title={this.props.title}
        question={
          <>
            <h5 style={{marginBottom: 0}}>Translate into Japanese:</h5>
            <Context>{this.state.context}</Context>
            {this.state.english}
          </>
        }
        wait={this.state.wait}
        status={this.state.status}
        sentence={true}
        onSubmit={this.checkAnswer}
        onClear={this.newSentence}
        placeHolder={this.state.placeHolder}
        input={this.state.userInput}
        answer={this.state.answer}
        diff={true}
        correct={this.state.correct}
        incorrect={this.state.incorrect}
        postAnswer={
          <div>
            <PreviousAnswers answers={this.state.prevAnswers} />
          </div>
        }
      >
      </QuizMaster>
    )
  }
}

SentenceTrainer.propTypes = {
  sentenceData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
}

export default SentenceTrainer
