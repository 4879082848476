import React from "react"
import styled from "styled-components"

const Context = styled.h4`
  margin-bottom: 0px;
  font-style: italic;
`

export default ({children}) => {
  if (children) {
    return(<Context>Context: { children }</Context>);
  }
  return (<></>)
}
