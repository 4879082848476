import React from "react"
import PropTypes from "prop-types"
import SEO from "../../components/seo"
import SentenceTrainer from "../../components/layouts/sentence_trainer"

const GenkiSentenceLesson = (props) => (
  <>
    <SEO
      title={`${props.pageContext.title} Sentence Practice${props.pageContext.earlyaccess ? " (Early Access)" : ""}`}
      description={`Interactive supplementary sentence and grammar practice tool for learning Genki Japanese ${props.pageContext.title}. Free for everyone.`}
    />
    <SentenceTrainer sentenceData={props.pageContext.sentenceData} title={`${props.pageContext.title} Sentence Practice${props.pageContext.earlyaccess ? " (Early Access)" : ""}`} />
  </>
)

GenkiSentenceLesson.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    sentenceData: PropTypes.array,
    earlyaccess: PropTypes.string
  }),
}

export default GenkiSentenceLesson
